<template>
  <v-app>
    <v-app-bar color="white" flat>
      <v-toolbar-title class="title">WhiskyCoach</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small depressed color="orange" href="mailto:hello@whiskycoach.com">Get Started</v-btn>
    </v-app-bar>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
  html, body {
    background: #ECEFF1;
  }

  .wc-code-req {
    font-family: monospace;
  }

  .wc-code-res {
    font-family: monospace;
    overflow-y: auto;
  }

  .wc-code-res textarea {
    font-size: 0.8rem;
    line-height: 1.3 !important;
  }

  .mono {
    font-family: monospace;
  }

  a.wc-link {
    color: #F57C00 !important;
  }
</style>