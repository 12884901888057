<template>
  <section class="my-10">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'PageSection',
  props: {
  }
};
</script>
