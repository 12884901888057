<template>
    <v-main class="blue-grey lighten-5 fill-height">

        <v-container>

                <page-section class="py-10 text-center">
                    <h1>WhiskyCoach is a free API for whisky recommendation websites and apps.</h1>
                    <h3>Request an account at <a class="wc-link" href="mailto:hello@whiskycoach.com">hello@whiskycoach.com</a></h3>
                </page-section>
                
                <page-section v-for="item in endpoints" :key="item.url">
                    <endpoint-docs
                        :title="item.title"
                        :description="item.description"
                        :type="item.type"
                        :url="item.url"
                        :response="item.response"
                        :params="item.params"
                        :response-text="item.responseText"
                    ></endpoint-docs>
                </page-section>

                <page-section class="py-10 text-center">
                    <h3 class="mb-0">Request a free account at <a class="wc-link" href="mailto:hello@whiskycoach.com">hello@whiskycoach.com</a></h3>
                </page-section>

            
        </v-container>

    </v-main>
</template>

<script>
import { mapState } from 'vuex';
import endpoints from '../../assets/endpoints.js';
import EndpointDocs from '../EndpointDocs.vue';
import PageSection from '../PageSection.vue';

export default {
  name: 'Home',
  components: { EndpointDocs, PageSection },
  data: () => ({
      endpoints: endpoints
  }),
  computed: {
    ...mapState({
    })
  }
};
</script>
