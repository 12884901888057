<template>
  
    <v-row>
      <v-col cols="12" md="6">
          <div class="pr-10">
          <h2 class="mb-4">{{title}}</h2>
          <p class="mb-8">{{description}}</p>

          <h3 class="mb-2">Parameters</h3>
          <v-divider class="mb-4" />
          <div class="mb-8">
        <template v-if="params && params.length">
            <div v-for="item in params" :key="item.name">
                <h4>{{item.name}} <span class="caption">{{item.required ? 'required' : 'optional'}}</span></h4>
                <p>{{item.description}}</p>
            </div>
        </template>
        <p v-else>No parameters</p>
          </div>

          <h3 class="mb-2">Response</h3>
          <v-divider class="mb-4" />
          <p>{{responseText}}</p>
          </div>
      </v-col>

      <v-col cols="12" md="6">

          <v-card dark
              color="blue-grey darken-3"
              elevation="0"
              class="wc-code-req rounded-lg rounded-bl-0 rounded-br-0">
              <v-card-text>
                  <div class="d-flex">
                      <p class="mb-0">
                          <span class="mr-4">{{type}}</span>
                          <span>{{url}}</span>
                      </p>
                  </div>
              </v-card-text>
          </v-card>
          <v-card dark 
              color="blue-grey darken-2"
              elevation="0"
              class="wc-code-res rounded-lg rounded-tl-0 rounded-tr-0">
              <v-card-text>
                  <v-textarea no-resize solo flat hide-details readonly
                    height="320"
                    background-color="transparent"
                    class="wc-code-res-input"
                    :value="JSON.stringify(response, undefined, 4)"></v-textarea>
              </v-card-text>
          </v-card>

      </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EndpointDocs',
  props: {
    title: String,
    description: String,
    type: String,
    url: String,
    response: Object,
    params: Array,
    responseText: String
  }
};
</script>
