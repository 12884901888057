import VueRouter from 'vue-router';
import home from './components/pages/home.vue';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', name: 'home', component: home }
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
});

export default router;