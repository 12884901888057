import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
          primary: '#0f70e8',
          secondary: '#0f70e8',
          accent: '#54ce34'
      }
    }
  },
  options: {
    customProperties: true
  }
});
