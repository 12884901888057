export default [
  {
    title: 'Search for products',
    description: 'Retrieve a list of products matching the given query.',
    type: 'GET',
    url: '/api/search/laphroaig',
    params: [
      {
        name: 'query',
        required: true,
        description: 'Search query, included within the URL.'
      }
    ],
    responseText: 'A list of product names and paths.',
    response: {
        "success": true,
        "data": [
          {
              "name": "Laphroaig 10 Year Old",
              "path": "laphroaig-10-year-old"
          },
          {
              "name": "Laphroaig 10 Year Old Cask Strength",
              "path": "laphroaig-10-year-old-cask-strength"
          },
          {
              "name": "Laphroaig 15 Year Old 200th Anniversary",
              "path": "laphroaig-15-year-old-200th-anniversary"
          },
          {
              "name": "Laphroaig 18 Year Old",
              "path": "laphroaig-18-year-old"
          },
          {
              "name": "Laphroaig 21 Year Old",
              "path": "laphroaig-21-year-old"
          },
          {
              "name": "Laphroaig 25 Year Old",
              "path": "laphroaig-25-year-old"
          },
          {
              "name": "Laphroaig 27 Year Old 57.4% 1980-2007",
              "path": "laphroaig-27-year-old-574-19802007"
          },
          {
              "name": "Laphroaig An Cuan Mor",
              "path": "laphroaig-an-cuan-mor"
          },
          {
              "name": "Laphroaig Cairdeas 2013 Port Wood",
              "path": "laphroaig-cairdeas-2013-port-wood"
          },
          {
              "name": "Laphroaig Cairdeas 2014 Amontillado",
              "path": "laphroaig-cairdeas-2014-amontillado"
          }
        ]
    }
  },

  {
    title: 'Get a producer',
    description: 'Retrieve detailed information about a producer or distillery.',
    type: 'GET',
    url: '/api/producer/ardbeg',
    params: [
      {
        name: 'producer',
        required: true,
        description: 'Producer name, included within the URL.'
      }
    ],
    responseText: 'Detailed information about the producer.',
    response: {
      "success": true,
      "data": {
          "name": "Ardbeg",
          "path": "ardbeg",
          "country": "Scotland",
          "region": "Islay",
          "founded": 1815,
          "description": "Ardbeg lies solitary, in a small cove off the south coast of Islay. It was once a stage for illegal distillation, when smugglers took advantage of the remote location and exceptional conditions for whisky production. Eventually, excise men seized the original, illegitimate buildings from the smugglers and destroyed them. It was not until 1815 that a legal distillery was established and founded by John McDougall. Sitting nearby leviathan distilleries; Laphroaig and Lagavulin, Ardbeg has always produced a very sought-after single malt, despite its production scale being less than half that of its neighbours."
      }
    }
  },

  {
    title: 'Get a product',
    description: 'Retrieve detailed information about a product.',
    type: 'GET',
    url: '/api/product/talisker-10-year-old',
    params: [
      {
        name: 'product',
        required: true,
        description: 'Product path, included within the URL.'
      }
    ],
    responseText: 'Detailed information about the product.',
    response: {
      "success": true,
      "data": {
          "path": "talisker-10-year-old",
          "name": "Talisker 10 Year Old",
          "age": 10,
          "country": "Scotland",
          "type": "Whisky",
          "rating": 8.83,
          "notes": {
              "product": "brine,banana,lingering,mellow,sweet",
              "family": "dry,smoky,spicy,honey,brine"
          },
          "cost": {
              "estimate": 40,
              "min": 40,
              "max": 50
          },
          "producer": {
              "name": "Talisker",
              "path": "talisker"
          },
          "profile": {
              "bodyVal": 0,
              "body": "Medium-bodied",
              "sweetDryVal": 0,
              "sweetDry": "Medium-sweet",
              "wineySmokyVal": 1,
              "wineySmoky": "Smoky",
              "richDelicateVal": -1,
              "richDelicate": "Rich"
          }
      }
    }
  },

  {
    title: 'Get similar product',
    description: 'Retrieve a list of similar products based on a given bottle.',
    type: 'GET',
    url: '/api/similar/lagavulin-16-year-old',
    params: [
      {
        name: 'product',
        required: true,
        description: 'Product path, included within the URL.'
      },
      {
        name: 'budget',
        required: false,
        description: 'Filter by maximum estimated cost, e.g. "budget=70" would only return products costing £70 or less.'
      }
    ],
    responseText: 'List of similar products. Maximum number of results is 10.',
    response: {
      "success": true,
      "data": [
          {
              "path": "caol-ila-distillers-edition",
              "name": "Caol Ila Distiller's Edition",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.68,
              "notes": {
                  "product": "caramel,complex,light,lingering,sherry",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 70,
                  "min": 70,
                  "max": 100
              },
              "producer": {
                  "name": "Caol Ila",
                  "path": "caol-ila"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "bruichladdich-port-charlotte-islay-barley-heavily-peated",
              "name": "Bruichladdich Port Charlotte Islay Barley Heavily Peated",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.76,
              "notes": {
                  "product": "",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 70,
                  "min": 70,
                  "max": 100
              },
              "producer": {
                  "name": "Bruichladdich",
                  "path": "bruichladdich"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "lagavulin-9-year-old-game-of-thrones-house-lannister",
              "name": "Lagavulin 9 Year Old Game of Thrones House Lannister",
              "age": 9,
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.8,
              "notes": {
                  "product": "",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 65,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Lagavulin",
                  "path": "lagavulin"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "kilchoman-10-year-old-2008-vintage",
              "name": "Kilchoman 10 Year Old 2008 Vintage",
              "age": 10,
              "country": "Scotland",
              "type": "Whisky",
              "rating": 9.03,
              "notes": {
                  "product": "",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 65,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Kilchoman",
                  "path": "kilchoman"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "ardbeg-corryvreckan",
              "name": "Ardbeg Corryvreckan",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 9.06,
              "notes": {
                  "product": "light,complex,peaty,citrus,vanilla",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 65,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Ardbeg",
                  "path": "ardbeg"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "laphroaig-cairdeas-2016-madeira",
              "name": "Laphroaig Cairdeas 2016 Madeira",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.83,
              "notes": {
                  "product": "",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 60,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Laphroaig",
                  "path": "laphroaig"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "laphroaig-cairdeas-2015",
              "name": "Laphroaig Cairdeas 2015",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 9.12,
              "notes": {
                  "product": "creamy,green,apple,lemon,earthy",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 60,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Laphroaig",
                  "path": "laphroaig"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "laphroaig-15-year-old-200th-anniversary",
              "name": "Laphroaig 15 Year Old 200th Anniversary",
              "age": 15,
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.77,
              "notes": {
                  "product": "vanilla,lemon,brown,sherry,sugar",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 60,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Laphroaig",
                  "path": "laphroaig"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "kilchoman-winter-2010-release",
              "name": "Kilchoman Winter 2010 Release",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.5,
              "notes": {
                  "product": "",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 60,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Kilchoman",
                  "path": "kilchoman"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          },
          {
              "path": "kilchoman-spring-2011-release",
              "name": "Kilchoman Spring 2011 Release",
              "age": "",
              "country": "Scotland",
              "type": "Whisky",
              "rating": 8.72,
              "notes": {
                  "product": "",
                  "family": "dry,smoky,peaty,earthy,citrusy"
              },
              "cost": {
                  "estimate": 60,
                  "min": 60,
                  "max": 70
              },
              "producer": {
                  "name": "Kilchoman",
                  "path": "kilchoman"
              },
              "profile": {
                  "bodyVal": 2,
                  "body": "Full-bodied",
                  "sweetDryVal": -1,
                  "sweetDry": "Dry",
                  "wineySmokyVal": 2,
                  "wineySmoky": "Very smoky",
                  "richDelicateVal": -2,
                  "richDelicate": "Very rich"
              }
          }
      ]
  }
  },

];